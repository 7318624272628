@include media-breakpoint-down('lg') {

  .header {
    #logo-container {
      #logo {
        max-width: 150px;
      }
    }

    #bogen {
      display: none;
    }

    .header-content {

      #header-title {
        @include font-size(3rem);
        color: white;
      }

      #header-subtitle {
        @include font-size(1.5rem);
      }

      #header-slogan {
        @include font-size(2rem);
      }
    }
  }

}
