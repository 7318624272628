// Include Variables
@import "variables";

// Include all of Bootstrap
@import "~bootstrap/scss/bootstrap";

// Include Bootstrap Icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");

// Include FiraSans Font
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,300;0,400;0,600;1,400&display=swap');

// Include Style
@import "style";
@import "responsive";