// Style.scss

// Create your own map
$custom-colors: (
        "green": $green,
        "lightblue": $lightblue
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

.text-green {
  color: $green;
}

.text-black {
  color: $black !important;
}

.bg-green {
  background-color: $green;
}

.bg-lightblue {
  background-color: $lightblue;
}

.border-green {
  border-color: $green !important;
}

.border-10 {
  border-width: 10px !important;
}

.highlight {
  color: $green;
  text-transform: uppercase;
  font-weight: 600;
}


// Header Styles
.header {
  #logo-container {
    position: relative;
    padding-right: 0;

    #bogen {
      /*min-width: 60px;*/
    }

    #logo {
      max-width: 220px;
    }

  }

  .header-content {
    padding: 50px 4% 32px 4%;

    #header-title {
      @include font-size(4rem);
      color: white;
      font-weight: 600;
      line-height: 1;
    }

    #header-subtitle {
      @include font-size(2rem);
      color: white;
    }

    #header-slogan {
      @include font-size(3rem);
      color: $green;
      font-weight: 600;
    }
  }
}

.teaser {
  font-weight: 400;
  background-color: white;
  display: flex;

  .teaser-content {
    border-right: solid 10px $secondary;
    flex-basis: 85%;

    .date {
      display: flex;
      justify-content: left;
      align-items: center;

      i {
        font-size: 32px;
        margin-right: 16px;
      }

      font-weight: 600;
    }

    .location {
      margin-top: 8px;
      font-size: .875rem;
      font-weight: 300;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 0;
    }
  }

  .teaser-more {
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 32px;
    }
  }
}


// Footer Styles
#footer {
  font-size: 1rem;

  #copyright {
    padding: 0 1rem;
  }
}

// Menu Styles

#navigation {
  .row {
    &.with-border {
      border-bottom: solid 2px $primary;
    }

  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        color: #707070;
        font-weight: 500;

        &:hover, &.active {
          color: $primary;
        }
      }
    }
  }

  .navbar-toggler {
    border: none;
  }

  #languages {
    .nav-link {
      padding-left: .5rem;
      padding-right: .5rem;

      .language-flag {
        height: 24px;
      }
    }


  }
}


// Form Styles

.form-control, .form-select {
  padding-left: 1rem;
  padding-right: 2rem;
  background-position: right 1rem center;

  &::placeholder {
    font-size: .85rem;
  }
}

form {
  fieldset {

    padding-bottom: 4rem;

    hr {
      height: 20px !important;
      width: 200px;
      margin-top: 0;
      background-color: $secondary;
      opacity: 1;
    }

    legend {
      font-size: 1.25rem;
      font-weight: bold;
      color: $primary;
      padding-top: 1rem;
      padding-bottom: 1rem;

      span {
        font-weight: 400;
      }
    }

    label {
      color: $primary;
      font-size: .9rem;
      margin-bottom: 4px;
    }

    .form-text {
      font-size: .85rem;
    }

    .form-control, .form-select {
      border: none;
      border-bottom: solid 2px $primary;
      border-radius: 0;
      padding-left: 0;

      &::placeholder {
        font-size: .85rem;
      }
    }

    .form-control[type=file] {
      background: rgba($primary, .25);
      padding-left: 1rem;
      border-bottom: none;
      font-size: .85rem;
    }

    .input-group-text {
      border: none;
      border-bottom: solid 2px $primary;
      border-radius: 0;
      background: rgba($primary, .25);
      padding-left: .75rem;
      padding-right: .75rem;
      font-size: .8rem;
    }


    &.bg-lightblue {
      .form-control, .form-select {
        background-color: $lightblue;
      }

    }

    .form-floating > .form-control,
    .form-floating > .form-select {
      padding-left: .25rem;
      padding-right: .5rem;
    }

    .form-floating > label {
      padding: .5rem .5rem .5rem 0;
      font-size: .95rem;
    }

    .form-floating {
      > .form-control:focus,
      > .form-control:not(:placeholder-shown),
      > .form-control-plaintext,
      > .form-select {
        ~ label {
          &::after {
            background-color: transparent;
          }
        }
      }
    }

    .form-select {
      background-position: right .5rem center;
      background-size: 24px 18px;
    }

    textarea {
      border-top: solid 2px $primary !important;
      border-bottom: solid 2px $primary;
      background-color: $white !important;
      padding: 1rem !important;
    }
  }


}

// Table

table {
  thead {
    color: $white;
  }
}

// Pagination

.page-item.disabled .page-link {
  background-color: initial;
}

.page-link {
  background-color: initial;
  border: none;

  &:hover {
    background-color: rgba($primary, .25);
  }
}
