// Colors
$ukj-blue: #0056A2;
$ukj-cyan: #00AEEB;
$ukj-green: #8DD449;

$primary: $ukj-blue;
$secondary: $ukj-cyan;
$green: $ukj-green;
$lightblue: #E5F7FD;

$light: $lightblue;


// Typography
$font-family-sans-serif: "Fira Sans", sans-serif;

$font-size-base: 1.125rem; // `18px`

$font-weight-base: 300;

$h1-font-size: $font-size-base * 2.3333;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1.075;
$h6-font-size: $font-size-base;

$headings-font-weight: 400;
$headings-color: $secondary;


// Spacing

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer / 4,
        2: $spacer / 2,
        3: $spacer,
        4: $spacer * 2,
        5: $spacer * 2.5,
        6: $spacer * 5, // `80px`
);


// Components

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;


// Buttons 
$input-btn-padding-y: .375rem !default;
$input-btn-padding-x: 2rem !default;

$btn-font-size: 1.25rem;


// Table
$table-cell-padding-y: 1.25rem;
$table-cell-padding-x: 1rem;

$table-border-color: rgba($primary, .25);

//$table-hover-color:           $primary;
$table-hover-bg-factor: 1;
$table-hover-bg: rgba($primary, .25);